exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-fishsandwichfestival-js": () => import("./../../../src/pages/fishsandwichfestival.js" /* webpackChunkName: "component---src-pages-fishsandwichfestival-js" */),
  "component---src-pages-freshfish-js": () => import("./../../../src/pages/freshfish.js" /* webpackChunkName: "component---src-pages-freshfish-js" */),
  "component---src-pages-frozenfish-js": () => import("./../../../src/pages/frozenfish.js" /* webpackChunkName: "component---src-pages-frozenfish-js" */),
  "component---src-pages-grandma-100-js": () => import("./../../../src/pages/grandma100.js" /* webpackChunkName: "component---src-pages-grandma-100-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photogallery-js": () => import("./../../../src/pages/photogallery.js" /* webpackChunkName: "component---src-pages-photogallery-js" */),
  "component---src-pages-smokedfish-js": () => import("./../../../src/pages/smokedfish.js" /* webpackChunkName: "component---src-pages-smokedfish-js" */)
}

